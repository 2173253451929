/**
 * Page ID
 */
const pageId = 'referrals';

const menuIds = {
  model: `${pageId}-model`,
  studio: `${pageId}-studio`,
};

export { pageId, menuIds };
