import { map, of, switchMap, iif } from 'rxjs';
import is from 'utils/is';
import authorization from 'services/authorization';
import application from 'services/application';
import type { NavigationMenuItem } from 'contracts';
import user from 'store/user';
import { IconList } from 'components/icon';
import messages from 'pages/redirection/legacy/legacy.i18n';

import { pageId, menuIds } from './id';

const menu: NavigationMenuItem = {
  entries: [
    {
      name: menuIds.model,
      label: messages.legacy.pages.referrals.navigationMenu.label,
      icon: { active: IconList.referralSolid, inactive: IconList.referralOutline },
      weight: 17,
      visibility$: authorization
        .getAuthorization$(pageId)!
        .pipe(
          switchMap((auth) =>
            iif(
              () => is.nullish(auth),
              of(auth),
              user.onViewTypeChange$.pipe(map(({ viewType }) => !application.Oranum && viewType === 'model'))
            )
          )
        ),
    },
    {
      name: menuIds.studio,
      label: messages.legacy.pages.referrals.navigationMenu.label,
      icon: { active: IconList.referralSolid, inactive: IconList.referralOutline },
      weight: 31,
      visibility$: authorization
        .getAuthorization$(pageId)!
        .pipe(
          switchMap((auth) =>
            iif(
              () => is.nullish(auth),
              of(auth),
              user.onViewTypeChange$.pipe(map(({ viewType }) => !application.Oranum && viewType === 'studio'))
            )
          )
        ),
    },
  ],
  activates: {
    [pageId]: [menuIds.model, menuIds.studio],
  },
};

export default menu;
