import { of, map, combineLatest, defer } from 'rxjs';
import { link } from 'services/navigation';
import authorization from 'services/authorization';
import user from 'store/user';
import account from 'store/account';
import application from 'services/application';
import settings from 'configurations/application';
import type { NavigationMenuItem } from 'contracts';
import { IconList } from 'components/icon';
import { getIsHolidayAwardsActive } from 'pages/shared/holiday-awards/utils';
import happen from 'utils/happen';
import is from 'utils/is';
import date from 'utils/date';
import unreadCounter from 'store/unread-counter';
import performerProfilePictures from 'store/performer-profile-pictures';
import performer from 'store/performer';

import messages from '../legacy.i18n';

const isInteractiveToyPromotionEnabled = (): boolean => {
  return happen(new Date()).lessThanOrEqual(date('2025-04-27').toLuxMidnight());
};

const menu: NavigationMenuItem = {
  entries: [
    {
      name: 'dashboard',
      label: messages.legacy.pages.dashboard.navigationMenu.label,
      icon: { active: IconList.homeSolid, inactive: IconList.homeOutline },
      link: () => link.dashboard(),
      weight: 1,
      visibility$: authorization.getAuthorization$('dashboard'),
    },
    {
      name: 'models',
      label: application.Oranum
        ? messages.legacy.pages.broadcasters.navigationMenu.label
        : messages.legacy.pages.models.navigationMenu.label,
      icon: { active: IconList.homeSolid, inactive: IconList.homeOutline },
      link: () => link.models(),
      weight: 1,
      visibility$: authorization.getAuthorization$('models')!.pipe(map((page) => page && application.LiveJasmin)),
    },
    {
      name: 'broadcasters',
      label: messages.legacy.pages.broadcasters.navigationMenu.label,
      icon: { active: IconList.groupTwoSolid, inactive: IconList.groupTwoOutline },
      link: () => link.models(),
      weight: 3,
      visibility$: authorization.getAuthorization$('models')!.pipe(map((page) => page && application.Oranum)),
    },
    {
      name: 'statisticsStudio',
      label: messages.legacy.pages.statisticsStudio.navigationMenu.label,
      icon: { active: IconList.chartLinesSolid, inactive: IconList.chartLinesOutline },
      link: () => link.statisticsStudio(),
      weight: 5,
      visibility$: authorization.getAuthorization$('statisticsStudio'),
    },
    {
      name: 'messengerSupport',
      label: messages.legacy.pages.messages.navigationMenu.label,
      icon: { active: IconList.envelopeSolid, inactive: IconList.envelopeOutline },
      link: () => link.messengerSupport({ page: 'inbox' }),
      weight: 7,
      visibility$: authorization
        .getAuthorization$('messengerSupport')!
        .pipe(map((allowed) => (is.nullish(allowed) ? allowed : allowed && user.isStudioView()))),
    },
    {
      name: 'statistics',
      label: messages.legacy.pages.statistics.navigationMenu.label,
      icon: { active: IconList.chartLinesSolid, inactive: IconList.chartLinesOutline },
      weight: 5,
      visibility$: undefined,
    },
    {
      name: 'profile',
      label: messages.legacy.pages.profile.navigationMenu.label,
      icon: { active: IconList.profileSolid, inactive: IconList.profileOutline },
      weight: 7,
      visibility$: undefined,
      notification$: defer(() =>
        combineLatest([performerProfilePictures.onChange$, performer.onStatusChange$]).pipe(
          map(() => {
            return (performerProfilePictures.hasPictures() &&
              !performerProfilePictures.hasSelectedPicturePortraitCrop()) ||
              (!performerProfilePictures.hasPictures() && !performer.hasClosedStatus())
              ? -1
              : 0;
          })
        )
      ),
      children: [
        {
          name: 'profilePhoto',
          label: messages.legacy.pages.profile.profilePhoto.navigationMenu.label,
          link: () => link.profile({ page: 'profile-picture' }),
          visibility$: authorization.getAuthorization$('profile'),
          notification$: defer(() =>
            combineLatest([performerProfilePictures.onChange$, performer.onStatusChange$]).pipe(
              map(() => {
                return (performerProfilePictures.hasPictures() &&
                  !performerProfilePictures.hasSelectedPicturePortraitCrop()) ||
                  (!performerProfilePictures.hasPictures() && !performer.hasClosedStatus())
                  ? -1
                  : 0;
              })
            )
          ),
          weight: 1,
        },
        {
          name: 'accountData',
          label: messages.legacy.pages.profile.accountData.navigationMenu.label,
          link: () => link.profile({ page: 'introduction' }),
          visibility$: authorization.getAuthorization$('profile', { page: 'introduction' }),
          weight: 2,
        },
        {
          name: 'prices',
          label: messages.legacy.pages.profile.prices.navigationMenu.label,
          link: () => link.profile({ page: 'prices' }),
          visibility$: authorization.getAuthorization$('profile', { page: 'prices' }),
          weight: 4,
        },
      ],
    },
    {
      name: 'myContent',
      label: messages.legacy.pages.myContent.navigationMenu.label,
      icon: { active: IconList.myContentSolid, inactive: IconList.myContentOutline },
      weight: 9,
      visibility$: undefined,
      notification$: defer(() =>
        combineLatest([unreadCounter.onMyContentFreeChange$, unreadCounter.onMyContentPremiumChange$]).pipe(
          map((access) => (access.some(Boolean) ? -1 : 0))
        )
      ),
      children: [
        {
          name: 'freeContent',
          label: messages.legacy.pages.myContent.freeContent.navigationMenu.label,
          link: () => link.myContentEditor({ type: 'free' }),
          notification$: defer(() => unreadCounter.onMyContentFreeChange$.pipe(map((unread) => (unread ? -1 : 0)))),
          visibility$: authorization.getAuthorization$('myContentEditor', { type: 'free' }),
          weight: 1,
        },
        {
          name: 'premiumContent',
          label: messages.legacy.pages.myContent.premiumContent.navigationMenu.label,
          link: () => link.myContentEditor({ type: 'premium' }),
          notification$: defer(() => unreadCounter.onMyContentPremiumChange$.pipe(map((unread) => (unread ? -1 : 0)))),
          visibility$: authorization.getAuthorization$('myContentEditor', { type: 'premium' }),
          weight: 2,
        },
        {
          name: 'payingSnapshots',
          label: messages.legacy.pages.myContent.payingSnapshots.navigationMenu.label,
          link: () => link.myContent({ page: 'paying-snapshot' }),
          visibility$: authorization.getAuthorization$('myContent', { page: 'paying-snapshot' }),
          weight: 5,
        },
      ],
    },
    {
      name: 'messages',
      label: messages.legacy.pages.messages.navigationMenu.label,
      icon: { active: IconList.envelopeSolid, inactive: IconList.envelopeOutline },
      weight: 11,
      notification$: defer(() =>
        combineLatest([
          unreadCounter.onMessagesMemberChange$,
          unreadCounter.onMessagesSupportChange$,
          unreadCounter.onMessagesSystemChange$,
        ]).pipe(map((access) => (access.some(Boolean) ? -1 : 0)))
      ),
      visibility$: combineLatest([
        authorization.getAuthorization$('messages')!,
        authorization.getAuthorization$('messengerSupport')!,
        authorization.getAuthorization$('messengerSystem')!,
      ]).pipe(map((access) => user.isModelView() && access.some(Boolean))),
      children: [
        {
          name: 'member',
          label: messages.legacy.pages.messages.member.navigationMenu.label,
          link: () => link.messages({ page: 'current-chats' }),
          visibility$: authorization.getAuthorization$('messages'),
          notification$: defer(() => unreadCounter.onMessagesMemberChange$),
          weight: 1,
        },
        {
          name: 'messengerSupport',
          label: messages.legacy.pages.messages.messengerSupport.navigationMenu.label,
          link: () => link.messengerSupport({ page: 'inbox' }),
          visibility$: authorization.getAuthorization$('messengerSupport'),
          notification$: defer(() => unreadCounter.onMessagesSupportChange$),
          weight: 3,
        },
        {
          name: 'messengerSystem',
          label: messages.legacy.pages.messages.messengerSystem.navigationMenu.label,
          link: () => link.messengerSystem({ page: 'inbox' }),
          visibility$: authorization.getAuthorization$('messengerSystem'),
          notification$: defer(() => unreadCounter.onMessagesSystemChange$),
          weight: 4,
        },
      ],
    },
    {
      name: 'promotionTools',
      label: messages.legacy.pages.promotionTools.navigationMenu.label,
      icon: { active: IconList.tagSolid, inactive: IconList.tagOutline },
      weight: 15,
      visibility$: combineLatest([
        authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'mobile' })!,
        authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'desktop' })!,
        authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'mobile' })!,
        authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'desktop' })!,
        authorization.getAuthorization$('promotionToolsVideoCall')!,
        authorization.getAuthorization$('promotionToolsVideoAds', { type: 'landscape' })!,
      ]).pipe(map((access) => access.some(Boolean))),
      notification$: defer(() => authorization.getAuthorization$('promotionToolsExplicitVideos')!).pipe(
        map((access) => (access && happen(new Date()).lessThanOrEqual(date('2024-05-11').toLuxMidnight()) ? -1 : 0))
      ),
      children: [
        {
          name: 'promoTeasersMobile',
          label: messages.legacy.pages.promotionTools.promoTeasersMobile.navigationMenu.label,
          link: () => link.promotionToolsPromoTeaser({ type: 'mobile' }),
          visibility$: authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'mobile' }),
          weight: 1,
        },
        {
          name: 'promoTeasersDesktop',
          label: messages.legacy.pages.promotionTools.promoTeasersDesktop.navigationMenu.label,
          link: () => link.promotionToolsPromoTeaser({ type: 'desktop' }),
          visibility$: authorization.getAuthorization$('promotionToolsPromoTeaser', { type: 'desktop' }),
          weight: 2,
        },
        {
          name: 'eroticTeasersMobile',
          label: messages.legacy.pages.promotionTools.eroticTeasersMobile.navigationMenu.label,
          link: () => link.promotionToolsEroticTeaser({ type: 'mobile' }),
          visibility$: authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'mobile' }),
          weight: 3,
        },
        {
          name: 'eroticTeasersDesktop',
          label: messages.legacy.pages.promotionTools.eroticTeasersDesktop.navigationMenu.label,
          link: () => link.promotionToolsEroticTeaser({ type: 'desktop' }),
          visibility$: authorization.getAuthorization$('promotionToolsEroticTeaser', { type: 'desktop' }),
          weight: 4,
        },
        {
          name: 'videoCall',
          label: messages.legacy.pages.promotionTools.videoCall.navigationMenu.label,
          link: () => link.promotionToolsVideoCall(),
          visibility$: authorization.getAuthorization$('promotionToolsVideoCall'),
          weight: 6,
        },
        {
          name: 'videoAdsPortrait',
          label: messages.legacy.pages.promotionTools.videoAds.navigationMenu.label,
          link: () => link.promotionToolsVideoAds({ type: 'landscape' }),
          visibility$: authorization.getAuthorization$('promotionToolsVideoAds', { type: 'landscape' }),
          weight: 7,
        },
      ],
    },
    {
      name: 'topExperts',
      label: messages.legacy.pages.topExperts.navigationMenu.label,
      icon: { active: IconList.tropheySolid, inactive: IconList.tropheyOutline },
      weight: 21,
      visibility$: combineLatest([authorization.getAuthorization$('bestOfTopModels')!]).pipe(
        map((access) => access.some(Boolean) && application.Oranum)
      ),
      children: [
        {
          name: 'leaderboard',
          label: messages.legacy.pages.topExperts.leaderboard.navigationMenu.label,
          link: () => link.bestOfTopModels({ page: 'topexperts' }),
          visibility$: authorization.getAuthorization$('bestOfTopModels'),
          weight: 2,
        },
      ],
    },
    {
      name: 'bestOf',
      label: messages.legacy.pages.bestOf.navigationMenu.label,
      icon: { active: IconList.tropheySolid, inactive: IconList.tropheyOutline },
      weight: 21,
      notification$: defer(() =>
        of(application.LiveJasmin && getIsHolidayAwardsActive()).pipe(map((enabled) => (enabled ? -1 : 0)))
      ),
      visibility$: combineLatest([
        authorization.getAuthorization$('holidayAwards')!,
        authorization.getAuthorization$('bestOfTopModels')!,
      ]).pipe(map((access) => access.some(Boolean) && application.LiveJasmin && !performer.hasFreeShowCategory())),
      children: [
        {
          name: 'topModels',
          label: messages.legacy.pages.bestOf.topModels.navigationMenu.label2,
          link: () => link.bestOfTopModels({ page: 'topmodels' }),
          visibility$: authorization.getAuthorization$('bestOfTopModels'),
          weight: 2,
        },
      ],
    },
    {
      name: 'payout',
      label: messages.legacy.pages.payout.navigationMenu.label,
      icon: { active: IconList.payoutSolid, inactive: IconList.payoutOutline },
      weight: 27,
      visibility$: undefined,
      notification$: defer(() => account.onPayoutOptionChange$.pipe(map((payoutOption) => (payoutOption ? -1 : 0)))),
      children: [
        {
          name: 'incomeStatistics',
          label: messages.legacy.pages.payout.incomeStatistics.navigationMenu.label,
          link: () => link.payout({ page: 'income-statistics' }),
          weight: 1,
          visibility$: authorization.getAuthorization$('payout', { page: 'income-statistics' }),
        },
        {
          name: 'payoutOptions',
          label: messages.legacy.pages.payout.payoutOptions.navigationMenu.label,
          link: () => link.payoutOptions({ page: 'general' }),
          visibility$: authorization.getAuthorization$('payoutOptions'),
          weight: 3,
          warning$: defer(() => account.onPayoutOptionChange$),
        },
      ],
    },
    {
      name: 'earnMore',
      label: messages.legacy.pages.earnMore.navigationMenu.label,
      icon: { active: IconList.flagSolid, inactive: IconList.flagOutline },
      weight: 33,
      visibility$: undefined,
      notification$: of((isInteractiveToyPromotionEnabled() && -1) || 0),
      children: [
        {
          name: 'liveJasminSelection',
          label: messages.legacy.pages.earnMore.liveJasminSelection.navigationMenu.label,
          weight: 1,
          link: () => link.earnMore({ page: 'jasmin-selection' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'jasmin-selection' }),
        },
        {
          name: 'rewardsClub',
          label: messages.legacy.pages.earnMore.rewardsClub.navigationMenu.label,
          link: () => link.earnMore({ page: 'rewards-club' }),
          weight: 3,
          visibility$: authorization.getAuthorization$('earnMore', { page: 'rewards-club' }),
        },
        {
          name: 'groupSession',
          label: messages.legacy.pages.earnMore.groupSession.navigationMenu.label,
          weight: 5,
          link: () => link.earnMore({ page: 'group-session' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'group-session' }),
        },
        {
          name: 'privateSessionDiscounts',
          label: messages.legacy.pages.earnMore.privateSessionDiscounts.navigationMenu.label,
          weight: 7,
          link: () => link.earnMore({ page: 'private-session-discounts' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'private-session-discounts' }),
        },
        {
          name: 'topMember',
          label: messages.legacy.pages.earnMore.topMember.navigationMenu.label,
          weight: 9,
          link: () => link.earnMore({ page: 'top-member' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'top-member' }),
        },
        {
          name: 'specialRequests',
          label: messages.legacy.pages.earnMore.specialRequests.navigationMenu.label,
          weight: 11,
          link: () => link.earnMore({ page: 'special-requests' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'special-requests' }),
        },
        {
          name: 'privateShows',
          label: messages.legacy.pages.earnMore.privateShows.navigationMenu.label,
          weight: 13,
          link: () => link.earnMore({ page: 'private-shows' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'private-shows' }),
        },
        {
          name: 'soldierAid',
          label: messages.legacy.pages.earnMore.soldierAid.navigationMenu.label,
          weight: 15,
          link: () => link.earnMore({ page: 'soldier-aid' }),
          visibility$: of(false),
        },
        {
          name: 'earnMoreFanClub',
          label: messages.legacy.pages.earnMore.fanClub.navigationMenu.label,
          weight: 19,
          link: () => link.earnMore({ page: 'fan-club' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'fan-club' }),
        },
        {
          name: 'tipsAndSurprises',
          label: messages.legacy.pages.earnMore.tipsAndSurprises.navigationMenu.label,
          weight: 21,
          link: () => link.earnMore({ page: 'tips' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'tips' }),
        },
        {
          name: 'actionRequests',
          label: messages.legacy.pages.earnMore.actionRequests.navigationMenu.label,
          weight: 23,
          link: () => link.earnMore({ page: 'action-requests' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'action-requests' }),
        },
        {
          name: 'interactiveToy',
          label: messages.legacy.pages.earnMore.interactiveToy.navigationMenu.label,
          weight: 25,
          link: () => link.earnMore({ page: 'interactive-toy' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'interactive-toy' }),
          new: () => application.LiveJasmin && isInteractiveToyPromotionEnabled(),
        },
        {
          name: 'beMyFan',
          label: messages.legacy.pages.earnMore.beMyFan.navigationMenu.label,
          weight: 27,
          link: () => link.earnMore({ page: 'bemyfan' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'bemyfan' }),
        },
        {
          name: 'mobileLive',
          label: messages.legacy.pages.earnMore.mobileLive.navigationMenu.label,
          weight: 29,
          link: () => link.earnMore({ page: 'mobile-live' }),
          visibility$: authorization.getAuthorization$('earnMore'),
        },
        {
          name: 'exclusiveModel',
          label: messages.legacy.pages.earnMore.exclusiveModel.navigationMenu.label,
          weight: 31,
          link: () => link.earnMore({ page: 'exclusive-model' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'exclusive-model' }),
        },
        {
          name: 'privateShowDiscount',
          label: messages.legacy.pages.earnMore.privateShowDiscount.navigationMenu.label,
          weight: 33,
          link: () => link.earnMore({ page: 'private-show-discount' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'private-show-discount' }),
        },
        {
          name: 'messagingStreaks',
          label: messages.legacy.pages.earnMore.messagingStreaks.navigationMenu.label,
          weight: 35,
          link: () => link.earnMore({ page: 'messaging-streak' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'messaging-streak' }),
        },
        {
          name: 'promoTeasers',
          label: messages.legacy.pages.earnMore.promoTeasers.navigationMenu.label,
          weight: 37,
          link: () => link.earnMore({ page: 'promo-teasers' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'promo-teasers' }),
        },
        {
          name: 'eroticTeasers',
          label: messages.legacy.pages.earnMore.eroticTeasers.navigationMenu.label,
          weight: 39,
          link: () => link.earnMore({ page: 'erotic-teasers' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'erotic-teasers' }),
        },
        {
          name: 'kingOfTheRoom',
          label: messages.legacy.pages.earnMore.kingOfTheRoom.navigationMenu.label,
          weight: 41,
          link: () => link.earnMore({ page: 'king-of-the-room' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'king-of-the-room' }),
        },
        {
          name: 'VIPShow',
          label: messages.legacy.pages.earnMore.VIPShow.navigationMenu.label,
          weight: 43,
          link: () => link.earnMore({ page: 'vip-show' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'vip-show' }),
        },
        {
          name: 'videoAds',
          label: messages.legacy.pages.earnMore.videoAds.navigationMenu.label,
          weight: 45,
          link: () => link.earnMore({ page: 'video-ads' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'video-ads' }),
        },
        {
          name: 'messengerIntroduction',
          label: messages.legacy.pages.earnMore.messenger.navigationMenu.label,
          weight: 47,
          link: () => link.earnMore({ page: 'messenger-introduction' }),
          visibility$: authorization.getAuthorization$('earnMore'),
        },
        {
          name: 'stories',
          label: messages.legacy.pages.earnMore.stories.navigationMenu.label,
          weight: 49,
          link: () => link.earnMore({ page: 'my-story' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'my-story' }),
        },
        {
          name: 'videoCalls',
          label: messages.legacy.pages.earnMore.videoCalls.navigationMenu.label,
          weight: 51,
          link: () => link.earnMore({ page: 'video-call' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'video-call' }),
        },
        {
          name: 'clubElite',
          label: messages.legacy.pages.earnMore.clubElite.navigationMenu.label,
          weight: 53,
          link: () => link.earnMore({ page: 'club-elite' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'club-elite' }),
        },
        {
          name: 'premiumPromotion',
          label: messages.legacy.pages.earnMore.premiumPromotion.navigationMenu.label,
          weight: 55,
          link: () => link.earnMore({ page: 'promo-video' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'promo-video' }),
        },
        {
          name: 'privateGame',
          label: messages.legacy.pages.earnMore.privateGame.navigationMenu.label,
          weight: 57,
          link: () => link.earnMore({ page: 'private-game' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'private-game' }),
        },
        {
          name: 'protection',
          label: messages.legacy.pages.earnMore.protection.navigationMenu.label,
          weight: 61,
          link: () => link.earnMore({ page: 'protection' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'protection' }),
        },
        {
          name: 'twoWayAudio',
          label: messages.legacy.pages.earnMore.twoWayAudio.navigationMenu.label,
          weight: 63,
          link: () => link.earnMore({ page: 'two-way-audio' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'two-way-audio' }),
        },
        {
          name: 'cam2Cam',
          label: messages.legacy.pages.earnMore.cam2Cam.navigationMenu.label,
          weight: 65,
          link: () => link.earnMore({ page: 'cam-to-cam' }),
          visibility$: authorization.getAuthorization$('earnMore', { page: 'cam-to-cam' }),
        },
      ],
    },
    {
      name: 'helpAndInfo',
      label: messages.legacy.pages.helpAndInfo.navigationMenu.label,
      icon: { active: IconList.megaphoneSolid, inactive: IconList.megaphoneOutline },
      weight: 35,
      notification$: defer(() =>
        combineLatest([unreadCounter.onNewsChange$]).pipe(map((access) => (access.some(Boolean) ? -1 : 0)))
      ),
      visibility$: undefined,
      children: [
        {
          name: 'incomeShares',
          label: messages.legacy.pages.helpAndInfo.incomeShares.navigationMenu.label,
          link: () => link.help({ type: 'income-shares' }),
          visibility$: authorization.getAuthorization$('help', { type: 'income-shares' }),
          weight: 3,
        },
        {
          name: 'wiki',
          label: messages.legacy.pages.helpAndInfo.wiki.navigationMenu.label,
          link: () => settings.envVars.wikiUri,
          visibility$: authorization.getAuthorization$('help'),
          weight: 4,
        },
      ],
    },
  ],

  activates: {
    dashboard: ['dashboard'],
    models: ['models', 'broadcasters'],
    statisticsStudio: ['statisticsStudio'],
    messengerSupport: ['messengerSupport'],

    profile: [
      (params) => {
        switch (params.page) {
          case 'profile-picture':
            return 'profilePhoto';

          case 'introduction':
            return 'accountData';

          case 'prices':
            return 'prices';

          default:
            return undefined;
        }
      },
    ],
    myContentEditor: ['premiumContent'],
    myContent: ['payingSnapshots'],
    messages: ['member'],
    messengerSystem: ['messengerSystem'],
    promotionToolsPromoTeaser: [
      (params) => {
        switch (params.type) {
          case 'mobile':
            return 'promoTeasersMobile';

          case 'desktop':
            return 'promoTeasersDesktop';

          default:
            return undefined;
        }
      },
    ],
    promotionToolsEroticTeaser: [
      (params) => {
        switch (params.type) {
          case 'mobile':
            return 'eroticTeasersMobile';

          case 'desktop':
            return 'eroticTeasersDesktop';

          default:
            return undefined;
        }
      },
    ],
    promotionToolsVideoCall: ['videoCall'],
    promotionToolsVideoAds: ['videoAdsPortrait'],
    referralsBroadcaster: ['referrals'],
    awards: ['awards'],
    bestOfTopModels: [
      (params) => {
        switch (params.page) {
          case 'topexperts':
            return 'leaderboard';

          case 'topmodels':
            return 'topModels';

          default:
            return undefined;
        }
      },
    ],
    musicLibrary: ['musicLibrary'],
    payout: [
      (params) => {
        return params.page === 'income-statistics' ? 'incomeStatistics' : undefined;
      },
    ],
    payoutOptions: [
      (params) => {
        return params.page === 'general' ? 'payoutOptions' : undefined;
      },
    ],
    earnMore: [
      (params) => {
        switch (params.page) {
          case 'jasmin-selection':
            return 'liveJasminSelection';

          case 'rewards-club':
            return 'rewardsClub';

          case 'group-session':
            return 'groupSession';

          case 'private-session-discounts':
            return 'privateSessionDiscounts';

          case 'top-member':
            return 'topMember';

          case 'special-requests':
            return 'specialRequests';

          case 'private-shows':
            return 'privateShows';

          case 'soldier-aid':
            return 'soldierAid';

          case 'fan-club':
            return 'earnMoreFanClub';

          case 'tips':
            return 'tipsAndSurprises';

          case 'action-requests':
            return 'actionRequests';

          case 'interactive-toy':
            return 'interactiveToy';

          case 'bemyfan':
            return 'beMyFan';

          case 'mobile-live':
            return 'mobileLive';

          case 'exclusive-model':
            return 'exclusiveModel';

          case 'private-show-discount':
            return 'privateShowDiscount';

          case 'messaging-streak':
            return 'messagingStreaks';

          case 'promo-teasers':
            return 'promoTeasers';

          case 'erotic-teasers':
            return 'eroticTeasers';

          case 'king-of-the-room':
            return 'kingOfTheRoom';

          case 'vip-show':
            return 'VIPShow';

          case 'video-ads':
            return 'videoAds';

          case 'messenger-introduction':
            return 'messengerIntroduction';

          case 'my-story':
            return 'stories';

          case 'video-call':
            return 'videoCalls';

          case 'club-elite':
            return 'clubElite';

          case 'promo-video':
            return 'premiumPromotion';

          case 'private-game':
            return 'privateGame';

          case 'protection':
            return 'protection';

          case 'two-way-audio':
            return 'twoWayAudio';

          case 'cam-to-cam':
            return 'cam2Cam';

          default:
            return undefined;
        }
      },
    ],
    help: [
      (params) => {
        return params.type === 'income-shares' ? 'incomeShares' : undefined;
      },
    ],
  },
};

export default menu;
