import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const HitPortalSelect = React.lazy(() => import(/* webpackPreload: true */ '../common'));
const DirectHit = React.lazy(() => import(/* webpackPreload: true */ '../direct-hit'));

const routes: Array<Route> = [
  {
    name: id.dashboard,
    path: '/{lang?}/dashboard',
    element: <HitPortalSelect />,
    hashParams: ['firstSingleLogin'],
  },
  {
    name: id.help,
    path: '/{lang?}/help/{type:list(income-shares)}',
    element: <DirectHit />,
  },
  {
    name: id.cookies,
    path: '/{lang?}/cookie-policy',
    element: <DirectHit />,
  },
  {
    name: id.faq,
    path: '/{lang?}/faq',
    element: <DirectHit />,
  },
  {
    name: id.recoverPassword,
    path: '/{lang?}/forgot-password',
    element: <DirectHit />,
  },
  {
    name: id.privacy,
    path: '/{lang?}/privacy-policy',
    hashParams: ['marketing', 'sensitiveData', 'biometricData'],
    element: <DirectHit />,
  },
  {
    name: id.terms,
    path: '/{lang?}/services-agreement',
    element: <DirectHit />,
  },
  {
    name: id.certifiedPartners,
    path: '/{lang?}/certified-partners',
    element: <DirectHit />,
  },
  {
    name: id.profile,
    // eslint-disable-next-line max-len
    path: '/{lang?}/profile/{page:list(introduction|profile-picture|prices|action-requests)}/{type:list(non-explicit|willingness)?}',
    element: <HitPortalSelect />,
  },
  {
    name: id.myContent,
    path: '/{lang?}/my-content/{page:list(paying-snapshot)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.myContentShorts,
    path: '/{lang?}/my-content/shorts/{privacy:list(free|premium)?}/{shortsId?}',
    element: <HitPortalSelect />,
  },
  {
    name: id.myContentEditor,
    path: '/{lang?}/my-content/editor/{type:list(free|premium)}',
    element: <HitPortalSelect />,
    searchParams: ['contentId', 'mediaType', 'paymentType'],
    hashParams: ['photos_folder', 'notes', 'videos_folder'],
  },
  {
    name: id.messages,
    path: '/{lang?}/messenger/{page:list(current-chats|recommended)}/{threadId?}',
    searchParams: ['memberNick', 'filter'],
    element: <HitPortalSelect />,
  },
  {
    name: id.messengerSupport,
    path: '/{lang?}/support-messenger/support/{page:list(inbox|sent|trash)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.messengerSystem,
    path: '/{lang?}/support-messenger/system/{page:list(inbox|sent|trash)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsEroticTeaser,
    path: '/{lang?}/promotion-tools/erotic-teaser/{type:list(mobile|desktop)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsPromoTeaser,
    path: '/{lang?}/promotion-tools/promo-teaser/{type:list(mobile|desktop)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsVideoAds,
    path: '/{lang?}/promotion-tools/video-ads/{type:list(portrait|landscape)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.promotionToolsVideoCall,
    path: '/{lang?}/promotion-tools/video-call',
    element: <HitPortalSelect />,
  },
  {
    name: id.bestOfTopModels,
    path: '/{lang?}/bestof/{page:list(topmodels|topexperts)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.awards,
    path: '/{lang?}/awards',
    element: <HitPortalSelect />,
  },
  {
    name: id.referrals,
    path: '/{lang?}/referrals/{page:list(member-referral)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.musicLibrary,
    path: '/{lang?}/music-library',
    element: <HitPortalSelect />,
  },
  {
    name: id.payout,
    path: '/{lang?}/payout/{page:list(income-statistics|payout-overview|billing-address)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.payoutOptions,
    path: '/{lang?}/payout/options/{page:list(general|details)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.earnMore,
    // eslint-disable-next-line max-len
    path: '/{lang?}/earn-more/{page:list(rewards-club|club-elite|protection|cam-to-cam|two-way-audio|jasmin-selection|private-game|fan-club|promo-video|tips-and-surprises|action-requests|interactive-toy|bemyfan|mobile-live|exclusive-model|private-show-discount|messaging-streak|erotic-teasers|promo-teasers|king-of-the-room|vip-show|messenger-introduction|video-call|my-story|soldier-aid|loyalfans|tips|special-requests|private-shows|video-ads|stories|group-session|private-session-discounts|top-member)}',
    element: <HitPortalSelect />,
  },
  {
    name: id.statisticsStudio,
    path: '/{lang?}/statistics/studio/dashboard',
    element: <HitPortalSelect />,
  },
];

export default routes;
