import type { RouteAuthorization } from 'contracts';
import { combineLatest, type Observable, of } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import application from 'services/application/application';
import is from 'utils/is';
import moduleAuth from 'store/module-authorization';

import parentAuth from '../../@@global/authorization';

const authorization: RouteAuthorization = {
  name: 'profilePrivacyFaceVisibility',
  authorization$: combineLatest([parentAuth.authorization$ as Observable<boolean>, moduleAuth.onChange$]).pipe(
    evalAuthorization(
      ([parentAuthResolved]) => is.nullish(parentAuthResolved) || !moduleAuth.ready,
      ([parentAuthResolved]) => of(parentAuthResolved && application.LiveJasmin && moduleAuth.hasHiddenFacePermission())
    )
  ),
};

export default authorization;
